'use strict'
angular.module('cb.account-service', [])
    .factory('AccountService', ["ApiService", function (ApiService) {

        var AccountService = {

            getAllUsers: function () {
                try {
                    var url = '/webapi/api/v1/staff/getAll';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getUserActivity: function (data) {
                try {
                    var url = '/webapi/api/v1/user/getUserActivityLog';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            updateUser: function (data) {
                try {
                    var url = '/webapi/api/v1/staff/editStaff';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            }
        };

        return AccountService;
    }]);
